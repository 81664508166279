.payments-wrapper {
  padding: 5px;
  max-width: 500px;

  #paypal-form-fields-container-DUDGDQ6QHVLEE .desc-label {
    font-size: 7vw;
  }
}

@media (min-width: 400px) {
  .payments-wrapper {
    padding: 10px;
  }
}

@media (min-width: 500px) {
  .payments-wrapper {
    padding: 15px;
  }
}

@media (min-width: 600px) {
  .payments-wrapper {
    #paypal-form-fields-container-DUDGDQ6QHVLEE .desc-label {
      font-size: 7vw;
    }
  }
}

@media (min-width: 700px) {
  .payments-wrapper {
    #paypal-form-fields-container-DUDGDQ6QHVLEE .desc-label {
      font-size: 5vw;
    }
  }
}

@media (min-width: 992px) {
  .payments-wrapper {
    #paypal-form-fields-container-DUDGDQ6QHVLEE .desc-label {
      font-size: 32px;
    }
  }
}
