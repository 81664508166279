.contact-icons {
  display: flex;
  flex-direction: row;
  padding: 15px 0px 15px 5px;
}

.link-logo {
  display: flex;
  height: 44px;
  margin: 0 20px;
}

@media (min-width: 998px) {
  .link-logo {
    height: 40px;
  }

  .contact-icons {
    padding: 15px 0px 15px 0px;
  }
}

@media (min-width: 1200px) {
  .link-logo {
    height: 35px;
  }
}