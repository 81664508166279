body {
  background-color: #cfc7d8;
  margin: 0;
}

li {
  font-family: "Poppins", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

p {
  font-family: "Poppins", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

#root {
  overflow: hidden;
}

.section-list-container {
  font-size: 5vw;
  padding-left: 30px;
  color: #ffffff;
}

.section-photo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.section-btn-text {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 7vw;
}

.section-title {
  display: flex;
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 11vw;
  color: #ffffff;
}

.section-content {
  padding: 0px 15px;
}

.section-A {
  background-color: #754480;
  display: flex;
  justify-content: center;
  padding: 50px 0px;
}

.section-B {
  background-color: #5b83b4;
  display: flex;
  justify-content: center;
  padding: 50px 0px;

}

.section-text {
  margin-left: 10px;
  font-size: 5vw;
}

.page-title {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 9vw;
  color: #181818;
}

.page-sub-title {
  font-family: "Poppins", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 6vw;
  color: #181818;
}

.page-list-container {
  font-size: 5vw;
  color: #181818;
}

.page-content-container {
  background-color: #c6adcd;
  display: flex;
  justify-content: center;
  min-height: 78.6vh;
}

.page-content {
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  padding: 25px 10px;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0px 25px 0px;
}

.secondary-text {
  display: flex;
  align-self: center;
  font-size: 5vw;
  color: #ffffff;
  padding: 0px 15px;
}

@media (min-width: 600px) {
  .section-title {
    font-size: 9vw;
  }

  .section-list-container {
    font-size: 4vw;
  }

  .secondary-text {
    font-size: 4vw;
  }

  .section-btn-text {
    font-size: 5vw;
  }

  .section-text {
    font-size: 4vw;
  }

  .page-title {
    font-size: 7vw;
  }

  .page-sub-title {
    font-size: 4vw;
  }

  .page-list-container {
    font-size: 4vw;
  }
}

@media (min-width: 768px) {
  .section-title {
    font-size: 8vw;
  }

  .section-list-container {
    font-size: 3vw;
  }

  .secondary-text {
    font-size: 3vw;
  }

  .section-btn-text {
    font-size: 5vw;
  }

  .section-text {
    font-size: 3vw;
  }

  .page-content {
    padding: 25px 50px;
  }

  .page-sub-title {
    font-size: 3.5vw;
  }

  .page-list-container {
    font-size: 3vw;
  }
}

@media (min-width: 900px) {
  .section-title {
    font-size: 6vw;
  }

  .section-btn-text {
    font-size: 4vw;
  }
}

@media (min-width: 992px) {
  .section-title {
    font-size: 45px;
  }

  .section-list-container {
    padding-left: 50px;
    font-size: 24px;
  }

  .secondary-text {
    font-size: 24px;
  }

  .section-btn-text {
    font-size: 34px;
  }

  .section-content {
    min-width: 70vw;
    max-width: 95vw;
  }

  .section-text {
    font-size: 20px;
  }

  .page-title {
    font-size: 45px;
  }

  .page-sub-title {
    font-size: 30px;
  }

  .page-content {
    min-height: 700px;
    max-width: 1100px;
  }

  .page-list-container {
    font-size: 20px;
  }
}

@media (min-width: 1400px) {
  .section-A {
    padding: 100px;
    min-height: 750px;
  }
  
  .section-B {
    padding: 100px;
    min-height: 750px;
  }

  .text-container {
    margin: 0px 0px 25px 0px;
  }

  .main-section {
    padding: 150px 100px;
  }

  .section-content {
    max-width: 1300px;
    margin: 0 auto;
  }

  .page-title {
    margin-bottom: 25px;
  }
}