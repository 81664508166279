.about-photo {
  max-width: 95vw;
  border-radius: 4px;
}

.about-photo-container {
  display: flex;
  justify-content: center;
  margin: 0px auto 25px auto;
}

@media (min-width: 600px) {
  .about-photo {
    max-width: 600px;
  }
}

@media (min-width: 992px) {
  .about-photo {
    max-width: 400px;
  }
}