.navbar {
  background-color: #754480;
  position: fixed;
  min-height: 75px;
  top: 0;
  left: 0;
  right: 0;
}

.nav-logo {
  display: flex;
  max-width: 30px;
}

.nav-title {
  align-self: center;
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-size: 5vw;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 0px;
}

.nav-link {
  color: #ffffff
}

.nav-link-list {
  justify-content: end;
}

.nav-item {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding: 5px 0px;
}

.llc {
  font-size: 3vw;
  margin-left: 6px;
}

.nav-bg {
  background-color: #754480;
}

@media (min-width: 350px) {
  .nav-logo {
    max-width: 40px;
  }
}

@media (min-width: 400px) {
  .nav-logo {
    max-width: 50px;
  }
}

@media (min-width: 768px) {
  .nav-title {
    font-size: 4vw;
  }
  .llc {
    font-size: 2vw;
  }
}

@media (min-width: 992px) {
  .nav-logo {
    display: flex;
    max-width: 50px;
  }

  .nav-title {
    font-size: 22px;
    margin-left: 10px;
  }

  .llc {
    font-size: 14px;
  }
}

@media (min-width: 1100px) {
  .nav-title {
    font-size: 30px;
  }

  .llc {
    font-size: 18px;
  }
}

@media (min-width: 1400px) {
  .nav-title {
    font-size: 36px;
  }
}