.footer-container {
  display: flex;
  flex-direction: column;
  min-height: 50px;
  background-color: #5c5c5c;
  color: #e2dfdf;
  padding: 20px 10px;
  width: 100%;
  position: relative;
  bottom: 0;
}

.footer-text {
  font-size: 4vw;
  margin-bottom: 0px;
}

.bold-footer-text {
  font-weight: 700;
  font-size: 4vw;
}

.footer-item-container {
  display: flex;
  align-self: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 15px 0px;
}

.mobile {
  display: flex;
}

.desktop {
  display: none;
}

.legal {
  margin: 0 auto;
}

@media (min-width: 600px) {
  .mobile {
    display: none;
  }
  
  .desktop {
    display: flex;
  }

  .footer-text {
    font-size: 3vw;
  }
  
  .bold-footer-text {
    font-size: 3vw;
  }
}

@media (min-width: 768px) {
  .footer-text {
    font-size: 2vw;
  }

  .bold-footer-text {
    font-size: 2vw;
  }

  .legal {
    max-width: 70vw;
  }
}

@media (min-width: 992px) {
  .footer-container {
    padding: 20px 20px;
  }

  .footer-text {
    font-size: 16px;
  }
  
  .bold-footer-text {
    font-size: 16px;
  }
}

@media (min-width: 1400px) {
  .footer-container {
    min-height: 50px;
    background-color: #5c5c5c;
    color: #e2dfdf;
    padding: 40px;
    width: 100%;
    position: relative;
    bottom: 0;
  }

  .legal {
    max-width: 790px;
  }
}
