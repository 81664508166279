.landing-content {
  background: url(../../assets/images/homeBgdark.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  min-height: 80vh;
  margin-top: 75px;
}

.landing-title {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 11vw;
  color: #ffffff;
  margin-top: 120px;
  margin-bottom: 10px;
}

.landing-secondary {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 7vw;
  color: #ffffff;
  margin: 0px 13px;
}

.landing-logo {
  max-width: 125px;
}

.home-photo {
  max-width: 300px;
  border-radius: 4px;
}

.landing-text {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-shadow: 0px 0px 10px black;
  margin: 0px 0px 0px 0px;
}

.home-btn {
  background-color: #ffffff;
  color: #5b83b4;
  max-width: 300px;
  margin: 10px 25px;
}

.consultation-text {
  color: #ffffff;
  margin-left: 10px;
  font-size: 5vw;
}

@media (min-width: 400px) {
  .landing-logo {
    max-width: 200px;
  }
}

@media (min-width: 500px) {
  .landing-logo {
    max-width: 250px;
  }
}

@media (min-width: 600px) {
  .landing-secondary {
    font-size: 5vw;
  }

  .home-btn {
    max-width: 350px;
  }

  .consultation-text {
    font-size: 4vw;
  }

  .landing-logo {
    max-width: 300px;
  }
}

@media (min-width: 768px) {
  .consultation-text {
    font-size: 3vw;
  }

  .landing-logo {
    max-width: 350px;
  }
}

@media (min-width: 992px) {
  .landing-title {
    font-size: 72px;
  }

  .landing-secondary {
    font-size: 45px;
  }

  .landing-text {
    margin: 100px 0px 0px 0px;
  }

  .consultation-text {
    font-size: 24px;
  }

  .home-btn {
    margin: 10px 0px;
  }
}

@media (min-width: 1200px) {
  .home-photo {
    max-width: 475px;
  }
}